import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Tharsis is an Empire along the North-West coast of `}<a parentName="p" {...{
        "href": "/Selar",
        "title": "Selar"
      }}>{`Selar`}</a>{` with strong diplomatic relations to `}<a parentName="p" {...{
        "href": "/Aljiuedum",
        "title": "Aljiuedum"
      }}>{`Aljiuedum`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      